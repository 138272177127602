import axios from 'axios'
import { BACKEND } from '@src/BACKEND'

export const FETCH_LOGIN_REQUEST = 'FETCH_LOGIN_REQUEST'
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS'
export const FETCH_LOGIN_FAILURE = 'FETCH_LOGIN_FAILURE'

export const fetchLoginRequest = () => {
  return {
    type: FETCH_LOGIN_REQUEST
  }
}

export const fetchLoginSuccess = (user) => {
  return {
    type: FETCH_LOGIN_SUCCESS,
    payload: user
  }
}

export const fetchLoginFailure = (error) => {
  return {
    type: FETCH_LOGIN_FAILURE,
    payload: error
  }
}

const LoginUser = (data, history) => {
  return (dispatch) => {
    dispatch(fetchLoginRequest())
    axios({
      method: 'post',
      url: `${BACKEND}admin/login`,
      data: {
        email: data.email,
        contrasena: data.contrasena
      }
    })
      .then(res => {
        dispatch(fetchLoginSuccess(res))
        localStorage.setItem('userData', JSON.stringify(res.data))
        localStorage.setItem('userAdminToken', JSON.stringify(res.data.token))

        const mostrarFuncionalidadCompleta = Number(res.data?.usuario?.mostrar_funcionalidad_completa)
        if (mostrarFuncionalidadCompleta === 1) {
          location.href = `/inicio`
        } else if (mostrarFuncionalidadCompleta === 0) {
          location.href = `/numeros`
        }
      })
      .catch(error => {
        dispatch(fetchLoginFailure(error.response))
      })
  }
}

export default LoginUser
