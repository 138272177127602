import { GET_PAGOS_DIARIO_ERROR, GET_PAGOS_DIARIO_REQUEST, GET_PAGOS_DIARIO_SUCCESS } from "../actions/getPagosDiarioActions"

const initialState = {
  loading: false,
  user: [],
  error: {
    response: {
      status: null
    }
  }
}

const GetPagosDiariosReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAGOS_DIARIO_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_PAGOS_DIARIO_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: {
          response: {
            status: null
          }
        }
      }
    case GET_PAGOS_DIARIO_ERROR:
      return {
        ...state,
        loading: false,
        user: [],
        error: action.payload
      }

    default:
      return state
  }
}

export default GetPagosDiariosReducer