import Avatar from '@components/avatar'
// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

export const isUserLoggedIn = () => localStorage.getItem('userData') && localStorage.getItem('userAdminToken')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const isClienteLoggedIn = () => localStorage.getItem('usuarioClienteData')
export const getClienteData = () => JSON.parse(localStorage.getItem('usuarioClienteData'))

export const getRutaCheck = () => sessionStorage.getItem('rutaCheck')

export const getToken = () => JSON.parse(localStorage.getItem('userAdminToken'))

export const RUBRO = [
  { value: 'Clubes', label: 'Clubes' },
  { value: 'Consorcios', label: 'Consorcios' },
  { value: 'Gimnasios', label: 'Gimnasios' },
  { value: 'Educación', label: 'Educación' },
  { value: 'Inmobiliarias', label: 'Inmobiliarias' },
  { value: 'otro', label: 'Otros rubros' }
]
/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const RenderAvatar = ({ name, size, className, img, fontSize }) => {
  return (
    !img ? <Avatar
      initials
      color='light-secondary'
      className={`my-20 ${className}`}
      content={name}
      contentStyles={{
        borderRadius: 0,
        fontSize: `calc(${fontSize}px)`,
        width: '100%',
        height: '100%'
      }}
      style={{
        height: `${size}px`,
        width: `${size}px`
      }}
    /> : <img
      className='user-avatar rounded mr-2 my-25 cursor-pointer'
      src={img}
      alt='user profile avatar'
      height={`${size}`}
      width={`${size}`}
    />
  )
}

export const formatDate = (date) => date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')