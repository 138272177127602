// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import LoginReducer from '@src/pages/authentication/login/store/reducer'
import LogoutReducer from '../../@core/layouts/components/navbar/store/reducer'
import ChangeProfileReducer from '@src/pages/perfil/store/reducers/changeProfileReducer'
import GetClientesReducer from '@src/pages/clientes/store/reducer/getClientesReducer'
import GetEmpresasReducer from '../../pages/empresas/store/reducer/getEmpresasReducer'
import GetPagosReducer from '../../pages/cobros/store/reducer/getPagosReducer'
import MailRegistroEmpresaReducer from '../../mailerlite/reducers/mailRegistroEmpresaReducer'
import NavUserDataReducer from '../../@core/layouts/components/navbar/store/storeUserData/navUserDataReducer'
import UpdateUserReducer from '../../@core/layouts/components/navbar/store/updateUserStore/updateUserReducer'
import GetFacturasReducer from '@src/pages/perfil/store/reducers/FacturasReducer'
import RefreshTokenReducer from '../../auth/refreshToken/refreshTokenReducer'
import GetDetailClienteReducer from '../../pages/clientes/store/reducer/getDetailClienteReducer'
import GetDetailEmpresaReducer from '../../pages/empresas/store/reducer/getDetailEmpresasReducer'
import GetDetailPagoReducer from '../../pages/cobros/store/reducer/getDetailPagoReducer'
import GetEstadisticasReducer from '../../pages/home/store/reducer/EstadisticasReducer'
import GetTransferenciasReducer from '../../pages/transferencias/store/reducer/getTransferenciasReducer'
import GetDetailTransferenciaReducer from '../../pages/transferencias/store/reducer/getDetailTransferenciaReducer'
import GetValidacionReducer from '../../pages/clientes/store/reducer/getValidacionReducer'
import EditEstadoValidacionReducer from '../../pages/clientes/store/reducer/putEstadoClienteReducer'
import GetValidacionClientesReducer from '../../pages/validacion/store/reducer/getValidacionClientesReducer'
import EditEstadoValidacionApiReducer from '../../pages/validacion/store/reducer/FetchEditEstadoValidacionApiReducer'
import EliminarUsuarioApiReducer from '../../pages/validacion/store/reducer/FetchEliminarUsuarioApiReducer'
import GetPagosDiariosReducer from '../../pages/pagos/store/reducers/getPagosDiarioReducer'
import EnviarTransferenciaReducer from '../../pages/pagos/store/reducers/putEnvioTransferenciaReducer'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  LoginReducer,
  LogoutReducer,
  ChangeProfileReducer,
  GetClientesReducer,
  GetEmpresasReducer,
  GetPagosReducer,
  MailRegistroEmpresaReducer,
  NavUserDataReducer,
  UpdateUserReducer,
  GetFacturasReducer,
  RefreshTokenReducer,
  GetDetailClienteReducer,
  GetDetailEmpresaReducer,
  GetDetailPagoReducer,
  GetEstadisticasReducer,
  GetTransferenciasReducer,
  GetDetailTransferenciaReducer,
  GetValidacionReducer,
  EditEstadoValidacionReducer,
  GetValidacionClientesReducer,
  EditEstadoValidacionApiReducer,
  EliminarUsuarioApiReducer,
  GetPagosDiariosReducer,
  EnviarTransferenciaReducer
})

export default rootReducer
