import axios from 'axios'
import { BACKEND } from '@src/BACKEND'

export const FETCH_LOGOUT_REQUEST = 'FETCH_LOGOUT_REQUEST'
export const FETCH_LOGOUT_SUCCESS = 'FETCH_LOGOUT_SUCCESS'
export const FETCH_LOGOUT_FAILURE = 'FETCH_LOGOUT_FAILURE'

export const fetchLogoutRequest = () => {
  return {
    type: FETCH_LOGOUT_REQUEST
  }
}

export const fetchLogoutSuccess = (user) => {
  return {
    type: FETCH_LOGOUT_SUCCESS,
    payload: user
  }
}

export const fetchLogoutFailure = (error) => {
  return {
    type: FETCH_LOGOUT_FAILURE,
    payload: error
  }
}

const LogoutAdmin = (history) => {
  return (dispatch) => {
    dispatch(fetchLogoutRequest())
    axios({
      method: 'post',
      url: `${BACKEND}admin/cerrar-sesion`
    })
      .then(res => {
        localStorage.removeItem('userData')
        localStorage.removeItem('userAdminToken')
        dispatch(fetchLogoutSuccess(res.data))
        history.push("/iniciar-sesion")
      })
      .catch(error => {
        dispatch(fetchLogoutFailure(error.response))
      })
  }
}

export default LogoutAdmin