import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_GET_VALIDACION_REQUEST = 'FETCH_GET_VALIDACION_REQUEST'
export const FETCH_GET_VALIDACION_SUCCESS = 'FETCH_GET_VALIDACION_SUCCESS'
export const FETCH_GET_VALIDACION_FAILURE = 'FETCH_GET_VALIDACION_FAILURE'

export const fetchGetValidacionRequest = () => {
    return {
        type: FETCH_GET_VALIDACION_REQUEST
    }
}

export const fetchGetValidacionSuccess = (cliente) => {
    return {
        type: FETCH_GET_VALIDACION_SUCCESS,
        payload: cliente
    }
}

export const fetchGetValidacionFailure = (error) => {
    return {
        type: FETCH_GET_VALIDACION_FAILURE,
        payload: error
    }
}

const FetchGetValidacionClientes = (setCliente, idAdmin, idCliente) => {
    return (dispatch) => {
        dispatch(fetchGetValidacionRequest())
        axios({
            method: 'GET',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}admin/${idAdmin}/imagenes-validacion`
        })
            .then(res => {
                dispatch(fetchGetValidacionSuccess(res.data))
                setCliente(res.data)
            })
            .catch(error => {
                dispatch(fetchGetValidacionFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: FetchGetValidacionClientes(setCliente, idAdmin, idCliente)
                    }
                ))
            })
    }
}

export default FetchGetValidacionClientes