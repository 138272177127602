import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_GET_DETAIL_PAGO_REQUEST = 'FETCH_GET_DETAIL_PAGO_REQUEST'
export const FETCH_GET_DETAIL_PAGO_SUCCESS = 'FETCH_GET_DETAIL_PAGO_SUCCESS'
export const FETCH_GET_DETAIL_PAGO_FAILURE = 'FETCH_GET_DETAIL_PAGO_FAILURE'

export const fetchGetDetailPagoRequest = () => {
    return {
        type: FETCH_GET_DETAIL_PAGO_REQUEST
    }
}

export const fetchGetDetailPagoSuccess = (pago) => {
    return {
        type: FETCH_GET_DETAIL_PAGO_SUCCESS,
        payload: pago
    }
}

export const fetchGetDetailPagoFailure = (error) => {
    return {
        type: FETCH_GET_DETAIL_PAGO_FAILURE,
        payload: error
    }
}

const FetchGetDetailPago = (setPago, idAdmin, idPago) => {
    return (dispatch) => {
        dispatch(fetchGetDetailPagoRequest())
        axios({
            method: 'GET',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}admin/${idAdmin}/pagos/${idPago}`
        })
            .then(res => {
                dispatch(fetchGetDetailPagoSuccess(res.data))
                setPago(res.data)
            })
            .catch(error => {
                dispatch(fetchGetDetailPagoFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: FetchGetDetailPago(setPago, idPago)
                    }
                ))
            })
    }
}

export default FetchGetDetailPago