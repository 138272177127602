import axios from "axios"
import { BACKEND } from "@src/BACKEND"
import { getToken } from "@utils"
import FetchRefreshToken from "@refreshToken"

export const FETCH_GET_PAGOS_REQUEST = "FETCH_GET_PAGOS_REQUEST"
export const FETCH_GET_PAGOS_SUCCESS = "FETCH_GET_PAGOS_SUCCESS"
export const FETCH_GET_PAGOS_FAILURE = "FETCH_GET_PAGOS_FAILURE"

export const fetchGetPagosRequest = () => {
  return {
    type: FETCH_GET_PAGOS_REQUEST
  }
}

export const fetchGetPagosSuccess = (pago) => {
  return {
    type: FETCH_GET_PAGOS_SUCCESS,
    payload: pago
  }
}

export const fetchGetPagosFailure = (error) => {
  return {
    type: FETCH_GET_PAGOS_FAILURE,
    payload: error
  }
}

const FetchGetPagos = (setPagos, idAdmin) => {
  return (dispatch) => {
    dispatch(fetchGetPagosRequest())
    axios({
      method: "GET",
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}admin/${idAdmin}/pagos`
    })
      .then((res) => {
        dispatch(fetchGetPagosSuccess(res.data))
        setPagos(res.data)
      })
      .catch((err) => {
        dispatch(fetchGetPagosFailure(err.response))
        dispatch(
          FetchRefreshToken({
            status: err.response?.status,
            fnc: FetchGetPagos(setPagos, idAdmin)
          })
        )
      })
  }
}

export default FetchGetPagos