import axios from "axios"
import { BACKEND } from "@src/BACKEND"
import { getToken } from "@utils"
import FetchRefreshToken from "@refreshToken"

export const FETCH_GET_CLIENTES_REQUEST = "FETCH_GET_CLIENTES_REQUEST"
export const FETCH_GET_CLIENTES_SUCCESS = "FETCH_GET_CLIENTES_SUCCESS"
export const FETCH_GET_CLIENTES_FAILURE = "FETCH_GET_CLIENTES_FAILURE"

export const fetchGetClientesRequest = () => {
  return {
    type: FETCH_GET_CLIENTES_REQUEST
  }
}

export const fetchGetClientesSuccess = (cliente) => {
  return {
    type: FETCH_GET_CLIENTES_SUCCESS,
    payload: cliente
  }
}

export const fetchGetClientesFailure = (error) => {
  return {
    type: FETCH_GET_CLIENTES_FAILURE,
    payload: error
  }
}

const FetchGetClientes = (setClientes, idAdmin) => {
  return (dispatch) => {
    dispatch(fetchGetClientesRequest())
    axios({
      method: "GET",
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}admin/${idAdmin}/clientes`
    })
      .then((res) => {
        dispatch(fetchGetClientesSuccess(res.data))
        setClientes(res.data)
      })
      .catch((err) => {
        dispatch(fetchGetClientesFailure(err.response))
        dispatch(FetchRefreshToken(
          {
              status: err.response?.status,
              fnc: FetchGetClientes(setClientes, idAdmin)
          }
        ))
      })
  }
}

export default FetchGetClientes