import { BACKEND } from "@src/BACKEND"
import axios from "axios"
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'
    export const GET_PAGOS_DIARIO_REQUEST = 'GET_PAGOS_DIARIOS_REQUEST'
    export const GET_PAGOS_DIARIO_SUCCESS = 'GET_PAGOS_DIARIOS_SUCCESS'
    export const GET_PAGOS_DIARIO_ERROR = 'GET_PAGOS_DIARIOS_ERROR'

    const GetPagosDiariosRequest = () => {
        return {
            type: GET_PAGOS_DIARIO_REQUEST
        }
    }

    const GetPagosDiariosSuccess = (facturas) => {
        return {
            type: GET_PAGOS_DIARIO_SUCCESS,
            payload: facturas
        }
    }

    const GetPagosDiariosError = (error) => {
        return {
            type: GET_PAGOS_DIARIO_ERROR,
            payload: error
        }
    }

    const GetPagosDiarios = (setpagosDiarios, idAdmin) => {
        return (dispatch) => {
            dispatch(GetPagosDiariosRequest())
                axios({
                method: 'GET',
                headers: { Authorization: `Bearer ${getToken()}` },
                url: `${BACKEND}admin/${idAdmin}/obtenerPagosDiarios`
            })
            .then((res) => {
                dispatch(GetPagosDiariosSuccess(res))
                setpagosDiarios(res.data)
            })
            .catch((error) => {
                dispatch(GetPagosDiariosError(error.response))
                dispatch(FetchRefreshToken(
            {
                status: error.response?.status,
                fnc: GetPagosDiarios(setpagosDiarios, idAdmin)
            }
            ))
        })
    }
}

export default GetPagosDiarios
