import axios from "axios"
import { BACKEND } from "@src/BACKEND"
import { getToken } from "@utils"
import FetchRefreshToken from "@refreshToken"

export const FETCH_GET_EMPRESAS_REQUEST = "FETCH_GET_EMPRESAS_REQUEST"
export const FETCH_GET_EMPRESAS_SUCCESS = "FETCH_GET_EMPRESAS_SUCCESS"
export const FETCH_GET_EMPRESAS_FAILURE = "FETCH_GET_EMPRESAS_FAILURE"

export const fetchGetEmpresasRequest = () => {
  return {
    type: FETCH_GET_EMPRESAS_REQUEST
  }
}

export const fetchGetEmpresasSuccess = (empresa) => {
  return {
    type: FETCH_GET_EMPRESAS_SUCCESS,
    payload: empresa
  }
}

export const fetchGetEmpresasFailure = (error) => {
  return {
    type: FETCH_GET_EMPRESAS_FAILURE,
    payload: error
  }
}

const FetchGetEmpresas = (setEmpresas, idAdmin) => {
  return (dispatch) => {
    dispatch(fetchGetEmpresasRequest())
    axios({
      method: "GET",
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}admin/${idAdmin}/empresas`
    })
      .then((res) => {
        dispatch(fetchGetEmpresasSuccess(res.data))
        setEmpresas(res.data)
      })
      .catch((err) => {
        dispatch(fetchGetEmpresasFailure(err.response))
        dispatch(
          FetchRefreshToken({
            status: err.response?.status,
            fnc: FetchGetEmpresas(setEmpresas, idAdmin)
          })
        )
      })
  }
}

export default FetchGetEmpresas