import { FETCH_EDIT_ESTADO_API_VALIDACION_FAILURE, FETCH_EDIT_ESTADO_API_VALIDACION_REQUEST, FETCH_EDIT_ESTADO_API_VALIDACION_SUCCESS } from "../actions/FetchEditEstadoValidacionApiAction"

const initialState = {
    loading: false,
    validacion: [],
    error: ""
}

const EditEstadoValidacionApiReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EDIT_ESTADO_API_VALIDACION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_EDIT_ESTADO_API_VALIDACION_SUCCESS:
            return {
                ...state,
                loading: false,
                validacion: action.payload,
                error: ""
            }
        case FETCH_EDIT_ESTADO_API_VALIDACION_FAILURE:
            return {
                ...state,
                loading: false,
                validacion: [],
                error: action.payload
            }
        default:
            return state
    }
}

export default EditEstadoValidacionApiReducer