import { lazy } from 'react'

const ClientesRoutes = [
    {
        path: '/cliente/ver/:id',
        component: lazy(() => import('@src/pages/clientes/detalleCliente'))
    },
    {
        path: '/cliente/ver/:id/print',
        layout: 'BlankLayout',
        component: lazy(() => import('@src/pages/clientes/invoices/acciones/Print'))
    }
]

export default ClientesRoutes