import { FETCH_LOGOUT_FAILURE, FETCH_LOGOUT_REQUEST, FETCH_LOGOUT_SUCCESS } from "./actions"

const initialState = {
    loading: false,
    user: [],
    error: {
      response: {
        status: null
      }
    }
  }
  
  const LogoutReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_LOGOUT_REQUEST:
        return {
          ...state,
          loading: true
        }
      case FETCH_LOGOUT_SUCCESS:
        return {
          ...state,
          loading: false,
          user: [],
          error: {
            response: {
              status: null
            }
          }
        }
      case FETCH_LOGOUT_FAILURE:
        return {
          ...state,
          loading: false,
          user: [],
          error: action.payload
        }
  
      default:
        return state
    }
  }
  
  export default LogoutReducer