import { FETCH_GET_VALIDACION_FAILURE, FETCH_GET_VALIDACION_REQUEST, FETCH_GET_VALIDACION_SUCCESS } from "../actions/getValidacionClientesAction"


const initialState = {
    loading: false,
    clientes: [],
    error: ""
}

const GetValidacionClientesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GET_VALIDACION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_GET_VALIDACION_SUCCESS:
            return {
                ...state,
                loading: false,
                clientes: action.payload,
                error: ""
            }
        case FETCH_GET_VALIDACION_FAILURE:
            return {
                ...state,
                loading: false,
                clientes: [],
                error: action.payload
            }

        default:
            return state
    }
}

export default GetValidacionClientesReducer