import { FETCH_GET_DETAIL_PAGO_FAILURE, FETCH_GET_DETAIL_PAGO_REQUEST, FETCH_GET_DETAIL_PAGO_SUCCESS } from "../actions/getDetailPagoAction"

const initialState = {
    loading: false,
    pago: [],
    error: ""
}

const GetDetailPagoReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GET_DETAIL_PAGO_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_GET_DETAIL_PAGO_SUCCESS:
            return {
                ...state,
                loading: false,
                pago: action.payload,
                error: ""
            }
        case FETCH_GET_DETAIL_PAGO_FAILURE:
            return {
                ...state,
                loading: false,
                pagos: [],
                error: action.payload
            }

        default:
            return state
    }
}

export default GetDetailPagoReducer