import {
  GET_ESTADISTICAS_REQUEST,
  GET_ESTADISTICAS_SUCCESS,
  GET_ESTADISTICAS_ERROR
} from "../actions/EstadisticasActions"

const initialState = {
  loading: false,
  estadisticas: [],
  error: ""
}

export default function GetEstadisticasReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ESTADISTICAS_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case GET_ESTADISTICAS_SUCCESS: {
      return {
        ...state,
        loading: false,
        estadisticas: action.payload,
        error: ""
      }
    }
    case GET_ESTADISTICAS_ERROR: {
      return {
        ...state,
        loading: false,
        estadisticas: [],
        error: action.payload
      }
    }
    default: return state
  }
}
