import { FETCH_ELIMINAR_USUARIO_API_FAILURE, FETCH_ELIMINAR_USUARIO_API_REQUEST, FETCH_ELIMINAR_USUARIO_API_SUCCESS } from "../actions/FetchEliminarUsuarioApiAction"

const initialState = {
    loading: false,
    validacion: [],
    error: ""
}

const EliminarUsuarioApiReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ELIMINAR_USUARIO_API_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_ELIMINAR_USUARIO_API_SUCCESS:
            return {
                ...state,
                loading: false,
                validacion: action.payload,
                error: ""
            }
        case FETCH_ELIMINAR_USUARIO_API_FAILURE:
            return {
                ...state,
                loading: false,
                validacion: [],
                error: action.payload
            }
        default:
            return state
    }
}

export default EliminarUsuarioApiReducer