import { lazy } from 'react'

const isFuncionalidadNoCompleta = () => {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const mostrarFuncionalidadCompleta = userData?.usuario?.mostrar_funcionalidad_completa
    return mostrarFuncionalidadCompleta === 0
}

const getLayoutForRoute = (path) => {
    return !isFuncionalidadNoCompleta() ? 'VerticalLayout' : 'BlankLayout'
}

const SameComponentForAllRoutes = lazy(() => import('@src/pages/home/Numeros'))

const All = isFuncionalidadNoCompleta()

const getComponentForRoute = (component) => {
    if (All) {
        return SameComponentForAllRoutes
    }
    return component
}

const PageRoutes = [
    {
        path: '/inicio',
        component: getComponentForRoute(lazy(() => import('@src/pages/home/Home'))),
        layout: getLayoutForRoute('/inicio')
    },
    {
        path: '/clientes',
        component: getComponentForRoute(lazy(() => import('@src/pages/clientes/Clientes'))),
        exact: true,
        layout: getLayoutForRoute('/inicio')
    },
    {
        path: '/empresas',
        component: getComponentForRoute(lazy(() => import('@src/pages/empresas/Empresas'))),
        layout: getLayoutForRoute('/inicio')
    },
    {
        path: '/cobros',
        component: getComponentForRoute(lazy(() => import('@src/pages/cobros/Cobros'))),
        exact: true,
        layout: getLayoutForRoute('/inicio')
    },
    {
        path: '/clearing',
        component: getComponentForRoute(lazy(() => import('@src/pages/transferencias/Transferencias'))),
        exact: true,
        layout: getLayoutForRoute('/inicio')
    },
    {
        path: '/iniciar-sesion',
        component: lazy(() => import('@src/pages/authentication/login/Login')),
        layout: 'BlankLayout',
        exact: true,
        meta: {
            authRoute: true
        }
    },
    {
        path: '/error',
        component: lazy(() => import('@src/pages/404/Error')),
        layout: 'BlankLayout'
    },
    {
        path: '/no-autorizado',
        component: lazy(() => import('@src/pages/NotAuthorized/NotAuthorized')),
        layout: 'BlankLayout'
    },
    {
        path: '/numeros',
        component: getComponentForRoute(lazy(() => import('@src/pages/home/Numeros'))),
        layout: getLayoutForRoute('/numeros')
    },
    {
        path: '/pagosDiarios',
        component: getComponentForRoute(lazy(() => import('@src/pages/pagos/Pagos'))),
        layout: getLayoutForRoute('/pagosDiarios')
    }
]

export default PageRoutes
