import axios from "axios"
import { BACKEND } from "@src/BACKEND"
import { getToken } from "@utils"
import FetchRefreshToken from "@refreshToken"

export const FETCH_GET_DETAIL_EMPRESA_REQUEST =
  "FETCH_GET_DETAIL_EMPRESA_REQUEST"
export const FETCH_GET_DETAIL_EMPRESA_SUCCESS =
  "FETCH_GET_DETAIL_EMPRESA_SUCCESS"
export const FETCH_GET_DETAIL_EMPRESA_FAILURE =
  "FETCH_GET_DETAIL_EMPRESA_FAILURE"

export const fetchGetDetailEmpresaRequest = () => {
  return {
    type: FETCH_GET_DETAIL_EMPRESA_REQUEST
  }
}

export const fetchGetDetailEmpresaSuccess = (empresa) => {
  return {
    type: FETCH_GET_DETAIL_EMPRESA_SUCCESS,
    payload: empresa
  }
}

export const fetchGetDetailEmpresaFailure = (error) => {
  return {
    type: FETCH_GET_DETAIL_EMPRESA_FAILURE,
    payload: error
  }
}

const FetchGetDetailEmpresa = (setEmpresa, idAdmin, idEmpresa, setPlanList) => {
  return (dispatch) => {
    dispatch(fetchGetDetailEmpresaRequest())
    axios({
      method: "GET",
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}admin/${idAdmin}/empresas/${idEmpresa}`
    })
      .then(res => {
        dispatch(fetchGetDetailEmpresaSuccess(res.data))
        setPlanList(res.data.planes)
        setEmpresa(res.data.empresa)
      })
      .catch(err => {
        dispatch(fetchGetDetailEmpresaFailure(err.response))
        dispatch(
          FetchRefreshToken({
            status: err.response?.status,
            fnc: FetchGetDetailEmpresa(setEmpresa, idAdmin, idEmpresa, setPlanList)
          })
        )
      })
  }
}

export default FetchGetDetailEmpresa