import {
  GET_FACTURAS_REQUEST,
  GET_FACTURAS_SUCCESS,
  GET_FACTURAS_ERROR
} from "../actions/FacturasActions"

const initialState = {
  loading: false,
  facturas: [],
  error: ""
}

export default function GetFacturasReducer(state = initialState, action) {
  switch (action.type) {
    case GET_FACTURAS_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case GET_FACTURAS_SUCCESS: {
      return {
        ...state,
        loading: false,
        facturas: action.payload,
        error: ""
      }
    }
    case GET_FACTURAS_ERROR: {
      return {
        ...state,
        loading: false,
        facturas: [],
        error: action.payload
      }
    }
    default: return state
  }
}
