import axios from 'axios'
import NavUserData from '@layouts/components/navbar/store/storeUserData/navUserDataAction'
import { BACKEND } from '@src/BACKEND'
import { toast } from 'react-toastify'
import { Check } from 'react-feather'
import Avatar from '@components/avatar'
import { getToken } from '@utils'
import FetchRefreshToken from '@src/auth/refreshToken/refreshTokenAction'

export const FETCH_CHANGEPROFILE_REQUEST = 'FETCH_CHANGEPROFILE_REQUEST'
export const FETCH_CHANGEPROFILE_SUCCESS = 'FETCH_CHANGEPROFILE_SUCCESS'
export const FETCH_CHANGEPROFILE_FAILURE = 'FETCH_CHANGEPROFILE_FAILURE'

const ToastSuccess = () => (
  <div className='toastify-header pb-0'>
    <div className='title-wrapper'>
      <Avatar size='sm' color='success' icon={<Check />} />
      <h6 className='toast-title'>¡Datos de usuario modificados correctamente!</h6>
    </div>
  </div>
)

const handleChangeProfile = () => {
  toast.success(<ToastSuccess />, {
    autoClose: 3000,
    hideProgressBar: true,
    closeButton: false
  })
}

export const fetchChangeProfileRequest = () => {
  return {
    type: FETCH_CHANGEPROFILE_REQUEST
  }
}

export const fetchChangeProfileSuccess = (user) => {
  return {
    type: FETCH_CHANGEPROFILE_SUCCESS,
    payload: user
  }
}

export const fetchChangeProfileFailure = (error) => {
  return {
    type: FETCH_CHANGEPROFILE_FAILURE,
    payload: error
  }
}

const ChangeProfileUser = (data, urlEmpresa) => {
  return (dispatch) => {
    dispatch(fetchChangeProfileRequest())
    axios({
      method: 'PUT',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}usuario/empresa/modificar`,
      data: {
        id_usuario_empresa: data.usuario.id_usuario_empresa,
        nombre: data.usuario.nombre,
        imagen_empresa: urlEmpresa ? urlEmpresa : data.usuario.imagen_empresa,
        nombre_gerente: data.usuario.nombre_gerente,
        apellido_gerente: data.usuario.apellido_gerente,
        telefono_gerente: data.usuario.telefono_gerente,
        rubro: data.usuario.rubro,
        rol: data.usuario.rol,
        nombre_resumen: data.usuario.nombre_resumen,
        descripcion: data.usuario.descripcion,
        mora: data.usuario.mora,
        baja: data.usuario.baja
      }
    })
      .then(res => {
        dispatch(fetchChangeProfileSuccess(res))
        localStorage.setItem('userData', JSON.stringify({ ...data, usuario: { ...data.usuario, imagen_empresa: urlEmpresa || data.usuario.imagen_empresa } }))
        dispatch(NavUserData())
        handleChangeProfile()
      })
      .catch(error => {
        dispatch(fetchChangeProfileFailure(error.response))
        
        dispatch(FetchRefreshToken(
          {
            status: error.response?.status,
            fnc: ChangeProfileUser(data, urlEmpresa)
          }
        ))
      })
  }
}

export default ChangeProfileUser