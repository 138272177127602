import { useEffect, useState } from 'react'
import classnames from 'classnames'
import * as Icon from 'react-feather'
import { NavItem, NavLink } from 'reactstrap'
import { useDispatch } from 'react-redux'
import Autocomplete from '@components/autocomplete'
import FetchGetClientes from '@src/pages/clientes/store/actions/getClientesAction'
import { isUserLoggedIn, RenderAvatar } from '@utils'
import './separacion.css'

const NavbarSearch = () => {
  const [userData] = useState(JSON.parse(localStorage.getItem('userData')))
  const dispatch = useDispatch()
  const [clientes, setClientes] = useState()
  const [navbarSearch, setNavbarSearch] = useState(false)
  const [bgOpen, setBgOpen] = useState(false)

  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      dispatch((FetchGetClientes(setClientes, userData.usuario.id_usuario_admin)))
    }
  }, [])

  const handleExternalClick = () => {
    if (navbarSearch === true) {
      setNavbarSearch(false)
      setBgOpen(false)
    }
  }

  const handleClearInput = setUserInput => {
    if (!navbarSearch) {
      setUserInput('')
    }
  }

  const onKeyDown = e => {
    if (e.keyCode === 27 || e.keyCode === 13) {
      setTimeout(() => {
        setNavbarSearch(false)
        setBgOpen(false)
      }, 1)
    }
  }

  const handleSuggestionItemClick = () => {
    setNavbarSearch(false)
    setBgOpen(false)
  }

  const handleListItemClick = (func, link, e) => {
    func(link, e)
    setTimeout(() => {
      setNavbarSearch(false)
      setBgOpen(false)
    }, 1)
  }

  return (
    <NavItem className='nav-search' onClick={() => setNavbarSearch(true)}>
      <NavLink className='nav-link-search'>
        <Icon.Search className='ficon' />
      </NavLink>
      <div
        className={classnames('search-input', {
          open: navbarSearch === true
        })}
      >
        <div className='search-input-icon'>
          <Icon.Search />
        </div>
        {navbarSearch ? (
          <Autocomplete
            className='form-control'
            suggestions={(clientes.map(cliente => {
              return { ...cliente, name: `${cliente.nombre} ${cliente.apellido}`, link: `/clientes/ver/${cliente.id_usuario_cliente}` }
            }))}
            filterKey='name'
            placeholder="Busca nombre o apellido de clientes"
            autoFocus={true}
            onSuggestionItemClick={handleSuggestionItemClick}
            externalClick={handleExternalClick}
            clearInput={(userInput, setUserInput) => handleClearInput(setUserInput)}
            onKeyDown={onKeyDown}
            onChange={() => setBgOpen(true)}
            customRender={(item, i, filteredData, activeSuggestion, onSuggestionItemClick, onSuggestionItemHover) => {
              return (
                <>
                  <li
                    className={classnames('suggestion-item', {
                      active: filteredData.indexOf(item) === activeSuggestion
                    })}
                    key={i}
                    onClick={e => handleListItemClick(onSuggestionItemClick, item.link, e)}
                    onMouseEnter={() => onSuggestionItemHover(filteredData.indexOf(item))}
                  >
                    <div
                      className="d-flex align-items-center"
                    >
                      <RenderAvatar size={40} name={item.name} fontSize={12} className='mr-1' />
                      <div className="d-flex flex-column font-weight-bolder">
                        <span className="mb-3px">{`${item.name}`}</span>
                        <span className='font-weight-light'>{item.email}</span>
                      </div>
                    </div>
                  </li>
                </>
              )
            }}
          />
        ) : null}
        <div className='search-input-close'>
          <Icon.X
            className='ficon'
            onClick={e => {
              e.stopPropagation()
              setNavbarSearch(false)
              setBgOpen(false)
            }}
          />
        </div>
        <div className={bgOpen && `backGroundNavList`}></div>
      </div>
    </NavItem>
  )
}

export default NavbarSearch
