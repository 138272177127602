import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_GET_DETAIL_TRANSFERENCIA_REQUEST = 'FETCH_GET_DETAIL_TRANSFERENCIA_REQUEST'
export const FETCH_GET_DETAIL_TRANSFERENCIA_SUCCESS = 'FETCH_GET_DETAIL_TRANSFERENCIA_SUCCESS'
export const FETCH_GET_DETAIL_TRANSFERENCIA_FAILURE = 'FETCH_GET_DETAIL_TRANSFERENCIA_FAILURE'

export const fetchGetDetailTransferenciaRequest = () => {
    return {
        type: FETCH_GET_DETAIL_TRANSFERENCIA_REQUEST
    }
}

export const fetchGetDetailTransferenciaSuccess = (transferencia) => {
    return {
        type: FETCH_GET_DETAIL_TRANSFERENCIA_SUCCESS,
        payload: transferencia
    }
}

export const fetchGetDetailTransferenciaFailure = (error) => {
    return {
        type: FETCH_GET_DETAIL_TRANSFERENCIA_FAILURE,
        payload: error
    }
}

const FetchGetDetailTransferencia = (setTransferencia, idAdmin, idPago) => {
    return (dispatch) => {
        dispatch(fetchGetDetailTransferenciaRequest())
        axios({
            method: 'GET',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}admin/${idAdmin}/transferencias/${idPago}`
        })
            .then(res => {
                dispatch(fetchGetDetailTransferenciaSuccess(res.data))
                setTransferencia(res.data)
            })
            .catch(error => {
                dispatch(fetchGetDetailTransferenciaFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: FetchGetDetailTransferencia(setTransferencia, idPago)
                    }
                ))
            })
    }
}

export default FetchGetDetailTransferencia