import { FETCH_GET_DETAIL_TRANSFERENCIA_FAILURE, FETCH_GET_DETAIL_TRANSFERENCIA_REQUEST, FETCH_GET_DETAIL_TRANSFERENCIA_SUCCESS } from "../actions/getDetailTransferenciaAction"

const initialState = {
    loading: false,
    transferencia: [],
    error: ""
}

const GetDetailTransferenciaReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GET_DETAIL_TRANSFERENCIA_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_GET_DETAIL_TRANSFERENCIA_SUCCESS:
            return {
                ...state,
                loading: false,
                transferencia: action.payload,
                error: ""
            }
        case FETCH_GET_DETAIL_TRANSFERENCIA_FAILURE:
            return {
                ...state,
                loading: false,
                transferencia: [],
                error: action.payload
            }

        default:
            return state
    }
}

export default GetDetailTransferenciaReducer