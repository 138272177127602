import { FETCH_REFRESH_TOKEN_FAILURE, FETCH_REFRESH_TOKEN_REQUEST, FETCH_REFRESH_TOKEN_SUCCESS } from "./refreshTokenAction"

const initialState = {
    loading: false,
    token: [],
    error: ''
}

const RefreshTokenReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REFRESH_TOKEN_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_REFRESH_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
                token: action.payload,
                error: ""
            }
        case FETCH_REFRESH_TOKEN_FAILURE:
            return {
                ...state,
                loading: false,
                token: [],
                error: action.payload
            }

        default:
            return state
    }
}

export default RefreshTokenReducer