import { FETCH_CHANGEPROFILE_FAILURE, FETCH_CHANGEPROFILE_REQUEST, FETCH_CHANGEPROFILE_SUCCESS } from "../actions/changeProfileAction"

const initialState = {
  loading: false,
  user: [],
  error: {
    response: {
      status: null
    }
  }
}

const ChangeProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CHANGEPROFILE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case FETCH_CHANGEPROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: {
          response: {
            status: null
          }
        }
      }
    case FETCH_CHANGEPROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        user: [],
        error: action.payload
      }

    default:
      return state
  }
}

export default ChangeProfileReducer