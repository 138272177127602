import { FETCH_UPDATE_USER_FAILURE, FETCH_UPDATE_USER_REQUEST, FETCH_UPDATE_USER_SUCCESS } from "./updateUserAction"

const initialState = {
    loading: false,
    user: [],
    error: ""
}

const UpdateUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_UPDATE_USER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_UPDATE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload,
                error: ""
            }
        case FETCH_UPDATE_USER_FAILURE:
            return {
                ...state,
                loading: false,
                user: [],
                error: action.payload
            }

        default:
            return state
    }
}

export default UpdateUserReducer