// ** Dropdowns Imports
import { Fragment, useEffect } from 'react'
import { ThemeToggler } from './ThemeToggler'

// ** Third Party Components
import { Menu } from 'react-feather'
import { NavItem, NavLink, Button } from 'reactstrap'
import NavbarSearch from './NavbarSearch'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import LogoutAdmin from './store/actions'

const NavbarUser = props => {
  // ** Props
  const { skin, setSkin, setMenuVisibility } = props

  const dispatch = useDispatch()
  const history = useHistory()

  const LogOut = () => {
    dispatch(LogoutAdmin(history))
  }

  return (
    <Fragment>
      <ul className='navbar-nav d-xl-none d-flex align-items-center'>
        <NavItem className='mobile-menu mr-auto'>
          <NavLink className='nav-menu-main menu-toggle hidden-xs is-active' onClick={() => setMenuVisibility(true)}>
            <Menu className='ficon' />
          </NavLink>
        </NavItem>
      </ul>
      <div className='bookmark-wrapper d-flex align-items-center container-fluid'>
        <NavbarSearch />
      </div>
      <div className='d-flex align-items-center justify-content-end container-fluid'>
        <div className=''>
          <NavItem className='d-none d-lg-block'>
            <NavLink className='nav-link-style'>
              <ThemeToggler
                skin={skin}
                setSkin={setSkin}
              />
            </NavLink>
          </NavItem>
        </div>
        <ul className='nav navbar-nav align-items-center'>
          <Button tag={Link} to="/iniciar-sesion" onClick={LogOut} className="" color="primary">Salir</Button>
        </ul>
      </div>
    </Fragment>
  )
}
export default NavbarUser
