import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { notifyErrorProgress } from '@src/components/Toast/failedToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_EDIT_ESTADO_VALIDACION_REQUEST = 'FETCH_EDIT_ESTADO_VALIDACION_REQUEST'
export const FETCH_EDIT_ESTADO_VALIDACION_SUCCESS = 'FETCH_EDIT_ESTADO_VALIDACION_SUCCESS'
export const FETCH_EDIT_ESTADO_VALIDACION_FAILURE = 'FETCH_EDIT_ESTADO_VALIDACION_FAILURE'

export const fetchEditEstadoValidacionRequest = () => {
    return {
        type: FETCH_EDIT_ESTADO_VALIDACION_REQUEST
    }
}

export const fetchEditEstadoValidacionSuccess = (prod) => {
    return {
        type: FETCH_EDIT_ESTADO_VALIDACION_SUCCESS,
        payload: prod
    }
}

export const fetchEditEstadoValidacionFailure = (error) => {
    return {
        type: FETCH_EDIT_ESTADO_VALIDACION_FAILURE,
        payload: error
    }
}

const FetchEditEstadoValidacion = (tipo, idAdmin, id, estado) => {
    return (dispatch) => {
        dispatch(fetchEditEstadoValidacionRequest())

        const endpointType = tipo === 'cliente' ? 'cliente' : 'empresa'

        return axios({
            method: 'PUT',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}admin/${idAdmin}/${endpointType}/${id}/modificar-estado`,
            data: { estado }
        })
        .then(res => {
            dispatch(fetchEditEstadoValidacionSuccess(res))
            if (estado === 'aprobado') {
                notifySuccessProgress(
                    "Validación de cliente",
                    `El cliente fue validado correctamente.`
                )
            } else if (estado === 'rechazado') {
                notifyErrorProgress(
                    "Validación de cliente",
                    `El cliente fue rechazado correctamente.`
                )
            }
            return res // Asegúrate de devolver la respuesta aquí
        })
        .catch(error => {
            dispatch(fetchEditEstadoValidacionFailure(error.response))

            dispatch(FetchRefreshToken(
                {
                    status: error.response?.status,
                    fnc: FetchEditEstadoValidacion(tipo, idAdmin, id, estado)
                }
            ))

            throw error // Asegúrate de lanzar el error para que se pueda manejar en el catch
        })
    }
}

export default FetchEditEstadoValidacion
