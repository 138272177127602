import { FETCH_GET_TRANSFERENCIAS_FAILURE, FETCH_GET_TRANSFERENCIAS_REQUEST, FETCH_GET_TRANSFERENCIAS_SUCCESS } from "../actions/getTransferenciasAction"


const initialState = {
    loading: false,
    pagos: [],
    error: ""
}

const GetTransferenciasReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GET_TRANSFERENCIAS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_GET_TRANSFERENCIAS_SUCCESS:
            return {
                ...state,
                loading: false,
                transferencias: action.payload,
                error: ""
            }
        case FETCH_GET_TRANSFERENCIAS_FAILURE:
            return {
                ...state,
                loading: false,
                transferencias: [],
                error: action.payload
            }

        default:
            return state
    }
}

export default GetTransferenciasReducer