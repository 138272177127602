import axios from "axios"
import { BACKEND } from "@src/BACKEND"
import { getToken } from "@utils"
import FetchRefreshToken from "@refreshToken"

export const FETCH_GET_TRANSFERENCIAS_REQUEST = "FETCH_GET_TRANSFERENCIAS_REQUEST"
export const FETCH_GET_TRANSFERENCIAS_SUCCESS = "FETCH_GET_TRANSFERENCIAS_SUCCESS"
export const FETCH_GET_TRANSFERENCIAS_FAILURE = "FETCH_GET_TRANSFERENCIAS_FAILURE"

export const fetchGetTransferenciasRequest = () => {
  return {
    type: FETCH_GET_TRANSFERENCIAS_REQUEST
  }
}

export const fetchGetTransferenciasSuccess = (transferencias) => {
  return {
    type: FETCH_GET_TRANSFERENCIAS_SUCCESS,
    payload: transferencias
  }
}

export const fetchGetTransferenciasFailure = (error) => {
  return {
    type: FETCH_GET_TRANSFERENCIAS_FAILURE,
    payload: error
  }
}

const FetchGetTransferencias = (setTransferencias, idAdmin) => {
  return (dispatch) => {
    dispatch(fetchGetTransferenciasRequest())
    axios({
      method: "GET",
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}admin/${idAdmin}/transferencias`
    })
      .then((res) => {
        dispatch(fetchGetTransferenciasSuccess(res.data))
        setTransferencias(res.data)
      })
      .catch((err) => {
        dispatch(fetchGetTransferenciasFailure(err.response))
        dispatch(
          FetchRefreshToken({
            status: err.response?.status,
            fnc: FetchGetPagos(setTransferencias, idAdmin)
          })
        )
      })
  }
}

export default FetchGetTransferencias