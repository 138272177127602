import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import NavUserData from '../storeUserData/navUserDataAction'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_UPDATE_USER_REQUEST = 'FETCH_UPDATE_USER_REQUEST'
export const FETCH_UPDATE_USER_SUCCESS = 'FETCH_UPDATE_USER_SUCCESS'
export const FETCH_UPDATE_USER_FAILURE = 'FETCH_UPDATE_USER_FAILURE'

export const fetchUpdateUserRequest = () => {
    return {
        type: FETCH_UPDATE_USER_REQUEST
    }
}

export const fetchUpdateUserSuccess = (data) => {
    return {
        type: FETCH_UPDATE_USER_SUCCESS,
        payload: data
    }
}

export const fetchUpdateUserFailure = (error) => {
    return {
        type: FETCH_UPDATE_USER_FAILURE,
        payload: error
    }
}

const FetchUpdateUser = (idUser) => {
    return (dispatch) => {
        dispatch(fetchUpdateUserRequest())
        axios({
            method: 'GET',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}usuario/empresa/${idUser}/ver-datos`
        })
            .then(res => {
                dispatch(fetchUpdateUserSuccess(res.data))
                localStorage.setItem('userData', JSON.stringify({ usuario: res.data }))
                dispatch(NavUserData())
            })
            .catch(error => {
                dispatch(fetchUpdateUserFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: FetchUpdateUser(idUser)
                    }
                ))
            })
    }
}

export default FetchUpdateUser