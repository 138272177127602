import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { notifyErrorProgress } from '@src/components/Toast/failedToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_ELIMINAR_USUARIO_API_REQUEST = 'FETCH_ELIMINAR_USUARIO_API_REQUEST'
export const FETCH_ELIMINAR_USUARIO_API_SUCCESS = 'FETCH_ELIMINAR_USUARIO_API_SUCCESS'
export const FETCH_ELIMINAR_USUARIO_API_FAILURE = 'FETCH_ELIMINAR_USUARIO_API_FAILURE'

export const fetchEliminarUsuarioApiRequest = () => {
    return {
        type: FETCH_ELIMINAR_USUARIO_API_REQUEST
    }
}

export const fetchEliminarUsuarioApiSuccess = (prod) => {
    return {
        type: FETCH_ELIMINAR_USUARIO_API_SUCCESS,
        payload: prod
    }
}

export const fetchEliminarUsuarioApiFailure = (error) => {
    return {
        type: FETCH_ELIMINAR_USUARIO_API_FAILURE,
        payload: error
    }
}

const FetchEliminarUsuarioApi = (usuario) => {
    return (dispatch) => {
        dispatch(fetchEliminarUsuarioApiRequest())

        const { idUsuarioApi, secretEmpresa } = usuario

        const requestBody = {
            id_usuario_api: idUsuarioApi,
            secret_empresa: secretEmpresa
        }

        axios({
            method: 'PUT',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}empresa/inhabilitar-cliente-api`,
            data: requestBody
        })
        .then(res => {
            dispatch(fetchEliminarUsuarioApiSuccess(res.data))
            notifyErrorProgress(
                "Validación de cliente API",
                `El cliente API fue rechazado correctamente.`
            )
        })
        .catch(error => {
            dispatch(fetchEliminarUsuarioApiFailure(error.response))

            dispatch(FetchRefreshToken(
                {
                    status: error.response?.status,
                    fnc: () => FetchEliminarUsuarioApi(usuario)
                }
            ))
        })
    }
}


export default FetchEliminarUsuarioApi
