import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_EDIT_ESTADO_API_VALIDACION_REQUEST = 'FETCH_EDIT_ESTADO_API_VALIDACION_REQUEST'
export const FETCH_EDIT_ESTADO_API_VALIDACION_SUCCESS = 'FETCH_EDIT_ESTADO_API_VALIDACION_SUCCESS'
export const FETCH_EDIT_ESTADO_API_VALIDACION_FAILURE = 'FETCH_EDIT_ESTADO_API_VALIDACION_FAILURE'

export const fetchEditEstadoValidacionApiRequest = () => {
    return {
        type: FETCH_EDIT_ESTADO_API_VALIDACION_REQUEST
    }
}

export const fetchEditEstadoValidacionApiSuccess = (prod) => {
    return {
        type: FETCH_EDIT_ESTADO_API_VALIDACION_SUCCESS,
        payload: prod
    }
}

export const fetchEditEstadoValidacionApiFailure = (error) => {
    return {
        type: FETCH_EDIT_ESTADO_API_VALIDACION_FAILURE,
        payload: error
    }
}

const FetchEditEstadoValidacionApi = (usuario) => {
    return (dispatch) => {
        dispatch(fetchEditEstadoValidacionApiRequest())

        const { emailApi, secretEmpresa } = usuario

        const requestBody = {
            email: emailApi,
            secret_empresa: secretEmpresa
        }

        axios({
            method: 'POST',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}empresa/autorizacion`,
            data: requestBody
        })
        .then(res => {
            dispatch(fetchEditEstadoValidacionApiSuccess(res.data))
            notifySuccessProgress(
                "Validación de cliente API",
                `El cliente API fue validado correctamente.`
            )
        })
        .catch(error => {
            dispatch(fetchEditEstadoValidacionApiFailure(error.response))

            dispatch(FetchRefreshToken(
                {
                    status: error.response?.status,
                    fnc: () => FetchEditEstadoValidacionApi(usuario)
                }
            ))
        })
    }
}


export default FetchEditEstadoValidacionApi
